import { IUserList } from "@netvision/lib-api-repo/dist/src/types"

export const parseHashAndSearch = (hash: string): Record<string, string | boolean> =>
  typeof hash === 'string' && hash
    ? hash
        .replace(/^#/, '')
        .split('&')
        .reduce((acc, field) => {
          const [key, value] = field.split('=')
          if (value) {
            acc[key.replace(/[&?]/g, '')] = value
          } else {
            acc[key.replace(/[&?]/g, '')] = true
          }
          return acc
        }, {} as Record<string, string | boolean>)
    : {}

export const toSnakeCase = (s: string) =>
  s
    .replace(/(?:^|\.?)([A-Z])/g, (_, y) => '_' + y.toLowerCase())
    .replace(/^_/, '')

export const setUserId = (id: string) => {
  try {
    window.localStorage.setItem('netvision:user-id', id)
  } catch(e) {
    console.error(e)
  }
}